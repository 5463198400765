import { UserValue } from './user.value';

export class UserValueDto {
    fullname: string;
    email: string;
    phoneNumber: string;
    birthdate: string;
    consent: {
        marketing: boolean;
        telemarketing: boolean;
    };

    constructor(data: UserValue) {
        this.fullname = data.fullname;
        this.email = data.email ?? '';
        this.phoneNumber = `${data.phone.prefix}${data.phone.number}`.trim();
        this.birthdate = data.birthdate.split('/').join('-');
        this.consent = {
            marketing: data.consent.marketing,
            telemarketing: data.consent.telemarketing,
        };
    }
}
